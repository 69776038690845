
	.section.content-area {
		position: relative;

		// &:after {
		// 	content: "";
		// 	position: absolute;
		// 	left: 0;
		// 	bottom: -10px;
		// 	width: 100%;
		// 	height: 10px;
		// 	background: rgba(0,0,0,0.1);

		// }
		.section-title {
			
		}

		p {
			margin-bottom: rem-calc(40);
		}
	}