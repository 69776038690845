
	.section.quotes {

		.quotes-holder {

			.quote {
				text-align: center;
				h2 {
					font-size: rem-calc(24);
					font-weight: 700;
					color: #244759;
					line-height: rem-calc(32);

					@include breakpoint(medium) {
						font-size: rem-calc(40);
						line-height: rem-calc(52);
					}
				}

				p {
					font-style: italic;
				}
			}
		}
	}