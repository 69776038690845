
	.section.newsletter {

		.newsletter-holder {
			padding-bottom: 50px;
			position: relative;
			background-color: $primary-color;
			border-bottom: 10px solid #d1d1d1;

			@include breakpoint(large) {
				float: left;
				width: calc(100% - 45px);
			}

			&:before {
				@include breakpoint(large) {
					position: absolute;
					left: -500%;
					top: 0;
					content: "";
					display: block;
					height: calc(100% + 10px);
					width: 500%;
					background: $primary-color;
					border-bottom: 10px solid #d1d1d1;
				}
			}

			.section-title {
				padding: 55px 0 0 20px;
				color: #fff;

				@include breakpoint(large) {
				}
			}

			p {
				margin: 0 20px 20px 20px;
				color: #fff;

				@include breakpoint(large) {
					margin: 20px 20px 20px 20px;
				}
			}
		}

		.img-holder {
			position: relative;
			margin-top: -54px;
			height: 100%;

			&:before {
				position: absolute;
				right: -500%;
				top: 0;
				content: "";
				display: block;
				height: calc(100% + 10px);
				width: 500%;
				background: $primary-color;
				border-bottom: 10px solid #d1d1d1;
			}
		}

		.fill-skew {
			display: none;
			float: right;
			margin-top: rem-calc(-28);
			background: #fff;
			width: rem-calc(45);
			height: 100%;
			-ms-transform: skew(0, -50deg); /* IE 9 */
		    -webkit-transform: skew(0, -50deg); /* Safari */
		    transform: skew(0, -50deg); /* Standard syntax */

		    @include breakpoint(large) {
		    	display: block;
		    }
		}
	}