
	.section.modules {
		@include breakpoint(xlarge) {
			margin-top: -75px;
		}
		.section-title {
			margin-bottom: rem-calc(75);

			@include breakpoint(xlarge) {
				margin-top: 75px;
			}
		}

		.module {
			margin-bottom: rem-calc(70);
			img {
				margin-bottom: rem-calc(30);
			}
			p {
				margin: 0 20px;
			}
		}

		.button {
			padding-left: rem-calc(30);
			padding-right: rem-calc(30);
			font-weight: 300;
			font-size: rem-calc(16);
		}
	}