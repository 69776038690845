
	.modal {
		border-top: 8px solid $secondary-color;
		padding: 0 0 rem-calc(120) 0;
		background: #fff;
		overflow-y: visible;
		max-width: 100%;

		header {
			text-align: center;
			background: $secondary-color;

			.logo {
				position: relative;
				bottom: -35px;
				width: 270px;
				max-width: 50%;
			}
		}
		.content {
			padding: rem-calc(85) 0 0 0;
			@include breakpoint(medium) {
				padding: rem-calc(85) rem-calc(100) 0 rem-calc(100);
			}

			h2 {
				margin-bottom: rem-calc(45);
				font-size: rem-calc(32);
				font-weight: 300;
				color: $primary-color;
			}
			h3 {
				margin-bottom: rem-calc(25);
				font-size: rem-calc(32);
				font-weight: 300;
				color: $primary-color;
			}

			input {
				margin-bottom: rem-calc(30);
				background: #eae9e9;
				border: 0;
			}

			.lang {
				display: block;
				margin-bottom: 20px;

				span {
					font-size: rem-calc(14);
				}

				@include breakpoint(medium) {
					display: inline;
				}
			}
		}

		.close-button {
			z-index: 1006;
			right: 10px;
			top: 10px;
			background: #244759;
			width: 34px;
			height: 34px;
			-webkit-border-radius: 34px;
			-moz-border-radius: 34px;
			border-radius: 34px;
			line-height: 34px;
			font-size: 24px;
			text-align: center;

			@include breakpoint(large) {
				right: -18px;
				top: -26px;
			}

			i {
				color: #fff;
			}
		}
	}