
	.section.usps {

		.usp {
			font-size: rem-calc(22);
			color: #244759;
			font-weight: 600;
			display: table;

			&:before {
				display: table-cell;
				padding-right: rem-calc(10);
				content: "\f058";
			    font-family: FontAwesome;
			    font-style: normal;
			    font-weight: normal;
			    text-decoration: inherit;
				/*--adjust as necessary--*/
				color: $secondary-color;
				vertical-align: middle;
			}

			span {
				display: table-cell;
				vertical-align: middle;
				line-height: rem-calc(26);
			}
		}
	}