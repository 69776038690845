
	.section.steps {
		@include breakpoint(medium) {
			background: url('/assets/assets/img/steps-back.gif') 0 0 repeat-x;
		}

		.step {
			position: relative;
			margin-bottom: rem-calc(60);

			@include breakpoint(medium) {
				margin-top: rem-calc(60);
			}
			.heading {
				display: block;
				position: relative;
				padding: 0;
				background: $primary-color;
				color: #fff;
				font-size: rem-calc(22);
				line-height: rem-calc(26);
				border-bottom: 10px solid #e5e5e5;

				@include breakpoint(medium) {
					font-size: rem-calc(18);
				}
				&:after {
					position: absolute;
					right: -10px;
					top: 36px;
					z-index: 2;
					content: "";
					display: block;
					width: 10px;
					height: calc(100% - 26px);
					background-color: #e5e5e5;
				}
			}
			h3 {
				margin-top: rem-calc(15);
				font-size: rem-calc(26);
				line-height: 1;
				color: $primary-color;
				font-weight: 300;
			}
			p {
				margin: 0;
				padding: 10px 0;
			}

			// &.animate {
			// 	h3 {

			// 		&:before {
			// 			@include breakpoint(medium) {
			// 				animation: 1s ease-out 0s 1 stepSkewOddBack;
			// 				animation-fill-mode:forwards;
			// 				display: block;
			// 			}
			// 		}

			// 		&:after {
			// 			@include breakpoint(medium) {
			// 				animation: 1s ease-out 0s 1 stepSkewOdd;
			// 				animation-fill-mode:forwards;
			// 				display: block;
			// 			}
			// 		}
			// 	}
			// }
		}
		.button {
			margin-bottom: 0;
		}
	}