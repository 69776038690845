
	footer.footer {
		margin: 0 0 0 0;
		padding: rem-calc(30) 0 0 0;
		background: $primary-color;
		color: #fff;
		font-size: rem-calc(14);
		line-height: rem-calc(22);
		border-top: 2px solid #fff;

		address {
			padding-bottom: rem-calc(30);
			font-style: normal;
		}

		ul {
			margin: 0;
			padding-bottom: rem-calc(30);
			list-style: none;

			a {
				color: #fff;
				text-decoration: underline;

				&:hover {
					text-decoration: none;
				}
			}
		}

		.copyright {
			margin: 0;
			padding-bottom: 30px;
		}

		img {
			margin-bottom: 15px;
		}
	}