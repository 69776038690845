
	.section.heading-area {
		padding-top: 100px;
		padding-bottom: 100px;
		position: relative;
		background-color: #e8e8e8;

		&:after {
			content: "";
			position: absolute;
			left: 0;
			bottom: -10px;
			width: 100%;
			height: 10px;
			background: rgba(0,0,0,0.1);

		}
		.section-title {
			margin-bottom: rem-calc(50);
			font-size: rem-calc(60);
			font-weight: 800;

			.subtitle {
				margin-top: 15px;
				font-weight: 200;
				font-size: rem-calc(24);
				color: 3333;
				font-style: italic;
			}
		}

		p {
			margin-bottom: rem-calc(40);
		}
	}