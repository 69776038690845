
	.section.team {

		.team-list {
			margin-top: rem-calc(50);
		}

		.team-member {
			position: relative;
			margin-bottom: rem-calc(65);

			&:after {
				position: absolute;
				left: 50%;
				top: 10px;
				content: "";
				display: block;
				z-index: 1;
				margin-left: -100px;
				width: rem-calc(215);
				height: rem-calc(215);
				-webkit-border-radius: 100%;
				-moz-border-radius: 100%;
				border-radius: 100%;
				overflow: hidden;
				background: #ddd;
			}

			.img-container {
				position: relative;
				z-index: 2;
				margin: 0 auto 25px auto;
				width: rem-calc(215);
				height: rem-calc(215);
				-webkit-border-radius: 100%;
				-moz-border-radius: 100%;
				border-radius: 100%;
				overflow: hidden;

				a {
					display: block;

					&:hover {
						&:before {
							content: "";
							display: block;
							position: absolute;
							left: 0;
							top: 0;
							width: rem-calc(215);
							height: rem-calc(215);
							-webkit-border-radius: 100%;
							-moz-border-radius: 100%;
							border-radius: 100%;
							overflow: hidden;
							background: rgba(238,116,2,0.7);
						}
					}
				}

				&:hover {
					+ .info-container {
						.linkedin {
							top: 75px;
							left: 50%;
							margin-left: -20px;
							font-size: rem-calc(50);
							color: #fff;
						}
					}
				}
			}

			.info-container {

				.function {
					margin-bottom: 10px;
					font-style: italic;
				}
				.linkedin {
					position: absolute;
					top: 282px;
					left: 50%;
					z-index: 5;
					margin-left: -12px;
					color: $primary-color;
					font-size: rem-calc(24);
					-webkit-transition-property: all;
  					-webkit-transition-duration: 0.25s;

  					&:hover {
						top: 75px;
						left: 50%;
						margin-left: -20px;
						font-size: rem-calc(50);
						color: #fff;
  					}
				}
			}
		}
	}