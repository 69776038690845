
	.section.questions {

		p {
			font-size: rem-calc(22);

			a {
				font-size: rem-calc(18);
				color: $primary-color;
				text-decoration: underline;
				font-weight: 400;

				&:hover {
					text-decoration: none;
				}
			}
		}

		.button {
			margin-top: rem-calc(15);
			margin-bottom: rem-calc(5);
			padding-left: rem-calc(100);
			padding-right: rem-calc(100);
			font-size: rem-calc(20);
		}
	}