
	.section.topbar {
		position: absolute;
		width: 100%;
		z-index: 5;
		margin-top: -62px;

		@include breakpoint(large) {
			position: static;
			margin-top: 0;
			background: $primary-color;
		}

		.logo-holder {
			position: relative;

			.logo {
				margin-left: -70px;
				max-width: rem-calc(140);

				img {
					max-height: rem-calc(43);
				}

				@include breakpoint(medium) {

				}

				@include breakpoint(large) {
					position: absolute;
					top: -20px;
					left: 0;
					margin-left: 0;
					max-width: 100%;
				}

				@include breakpoint(xlarge) {
					top: -50px;
				}
			}
		}

		.offcanvas-opener {
			padding: 10px 0;
			color: #fff;
			font-size: rem-calc(24);
		}

		.topnav {
			margin: 0;
			padding: 0;
			list-style: none;
			overflow: hidden;

			li {
				display: inline;
				padding: 20px 0;

				&:last-child {
					a {
						padding-right: 0;
					}
				}

				a {
					position: relative;
					display: inline-block;
					padding: 0 20px 20px 20px;
					color: #fff;

					&.active {
						&:after {
							position: absolute;
							bottom: -6px;
							left: 50%;
							margin-left: -6px;
							content: "";
							display: block;
							width: 12px;
							height: 12px;
							background: #e4e5df;
							transform: rotate(45deg);
						}
					}

					&:hover {
						text-decoration: underline;
					}

					&.cta {
						position: relative;
						right: 0;
						padding: 20px 30px;
						background: $primary-color;

						&.animate {
							animation: 1s ease-out 0s 1 ctaMoveDown;
							animation-fill-mode:forwards;
						}
					}
				}
			}
		}
	}


	@keyframes ctaMoveDown {
		0% {
			top: 0;
		}
		100% {
			top: 10px;
		}
	}