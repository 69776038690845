
	.section.visual {
		position: relative;
		margin-bottom: rem-calc(60);

		@include breakpoint(large) {
			margin-bottom: rem-calc(0);
			min-height: rem-calc(650);

			&.small {
				min-height: rem-calc(445);
			}
		}

		&.small {
			border-bottom: 10px solid #d1d1d1;
		}

		.image-holder {
			background-position: 0 100%;
			background-repeat: no-repeat;
			background-size: cover;
			min-height: rem-calc(250);

			@include breakpoint(large) {
				position: absolute;
				left: 0;
				top: 0;
				height: 100%;
				width: 100%;
			}

			+ .grid-container {
				position: relative;
				z-index: 2;

				.grid-x {
					margin: 0 -20px;

					@include breakpoint(xlarge) {
						padding-right: 20px;
					}
				}
			}
		}

		.visual-content {
			position: relative;
			z-index: 2;
			width: 100%;
			padding: rem-calc(30);
			background: $primary-color;

			&:before {
				@include breakpoint(large) {
					position: absolute;
					right: 0;
					bottom: -10px;
					z-index: 1;
					content: "";
					display: block;
					width: 98%;
					height: 10px;
					background: rgba(0,0,0,0.1);
				}
			}
			&:after {
				@include breakpoint(large) {
					position: absolute;
					right: -10px;
					top: 10px;
					z-index: 1;
					content: "";
					display: block;
					width: 10px;
					height: 100%;
					background: rgba(0,0,0,0.1);
				}
			}

			@include breakpoint(large) {
				position: relative;
				margin-left: rem-calc(20);
				margin-top: rem-calc(120);
				padding: rem-calc(30);
			}
			@include breakpoint(xlarge) {
				margin-left: 0;
				visibility: hidden;
			}

			h1 {
				font-size: rem-calc(32);
				color: #fff;
				font-weight: 800;

				span {
					color: $secondary-color;
				}

				@include breakpoint(large) {
					font-size: rem-calc(60);
					line-height: 1;
				}
				@include breakpoint(xlarge) {
					padding-right: rem-calc(35);
				}
			}

			p {
				margin: 0;
				color: #fff;
				font-size: rem-calc(18);
				line-height: rem-calc(24);

				@include breakpoint(large) {
					font-size: rem-calc(24);
					line-height: rem-calc(32);
				}
			}
		}
	}