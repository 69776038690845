
	body {
		@include breakpoint(large) {
			border :2px solid #fff;
			/* Fix for wull width weird skewed sections */
			overflow-x: hidden;
		}
	}

	.section {
		margin: 0 auto;
		padding: rem-calc(75) 0;
		max-width: rem-calc(1900);

		&.nopadding {
			padding: 0;
		}
		&.nopadding-top {
			padding-top: 0;
		}
		&.nopadding-bottom {
			padding-bottom: 0;
		}

		&.full-width {
			max-width: 100%;
		}

		&.grid {
			background-image: url('/assets/assets/img/grid-dark.png');
			&.secondary {
				background-image: url('/assets/assets/img/grid.png');
			}
			&.grid-light {
				background-image: url('/assets/assets/img/grid-light.png');
			}
			background-position: 0 0;
		}

		&.primary {
			background-color: $primary-color;
			color: #fff;

			h2 {
				margin: 50px 0 30px 0;
				&.section-title {
					color: #fff;
				}
			}
		}

		&.secondary {
			background-color: $secondary-color;
			color: #fff;
		}

		&.bordered {
			border-bottom: 10px solid #d1d1d1;
		}

		&.grey {
			background: #f6f6f6;
		}

	}

	.section-title {
		margin-bottom: rem-calc(40);
		font-size: rem-calc(40);
		line-height: 46px;
		font-weight: 300;
		color: $primary-color;

		.secondary & {
			color: #fff;
		}

		.subtitle {
			display: block;
			margin-top: 5px;
			font-size: rem-calc(20);
			line-height: rem-calc(36);
		}
	}
	.section-subtitle {
		font-size: rem-calc(18);
		font-weight: 600;
		color: $primary-color;
	}

	.button {
		padding-left: rem-calc(25);
		padding-right: rem-calc(25);
		font-size: rem-calc(16);
		font-weight: 600;
		text-decoration: none !important;
		text-transform: uppercase;

		&.secondary {
			color: #fff;
			font-size: rem-calc(16);
			text-transform: uppercase;
			font-weight: 600;
		}

		&.btn-white {
			background: #fff;
			color: $primary-color;

			&:hover {
				background: #ddd;
			}
		}

		&.block {
			display: block;
			text-align: center;
		}

		&.large {
			padding-top: rem-calc(17);
			padding-bottom: rem-calc(17);
			font-size: rem-calc(20);
			font-weight: 700;

			&:hover {
				color: #fff;
			}
		}
	}

	[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea {
		height: rem-calc(55);
	}

	.off-canvas {

		.close-button {
			span {
				color: #fff;
			}
		}

		.menu {
			margin-top: rem-calc(50);
			a {
				color: #fff;
				font-weight: bold;
			}
		}
	}

	::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	  font-style: italic;
	  font-size: rem-calC(18);
	}
	::-moz-placeholder { /* Firefox 19+ */
	  font-style: italic;
	  font-size: rem-calC(18);
	}
	:-ms-input-placeholder { /* IE 10+ */
	  font-style: italic;
	  font-size: rem-calC(18);
	}
	:-moz-placeholder { /* Firefox 18- */
	  font-style: italic;
	  font-size: rem-calC(18);
	}

	.callout.alert {
		margin-top: -16px;
		font-size: rem-calc(12);
	}