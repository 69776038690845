
	.section.call-to-action {

		.section-title {
			color: #fff;
		}

		.title-holder {
			padding: 30px 0;
			background: $secondary-color;

			@include breakpoint(large) {
				float: left;
				padding: 80px 0;
				position: relative;
				width: calc(100% - 45px);
				border-bottom: 10px solid #d1d1d1;
			}

			&:before {
				@include breakpoint(large) {
					position: absolute;
					left: -500%;
					top: 0;
					content: "";
					display: block;
					height: calc(100% + 10px);
					width: 500%;
					background: $secondary-color;
					border-bottom: 10px solid #d1d1d1;
				}
			}
		}

		.content-holder {
			position: relative;
			height: 100%;
			background: $primary-color;
			border-bottom: 10px solid #d1d1d1;

			@include breakpoint(large) {
				margin-top: 55px;
			}

			&:after {
				@include breakpoint(large) {
					position: absolute;
					right: -500%;
					top: 0;
					content: "";
					display: block;
					height: calc(100% + 10px);
					width: 500%;
					background: $primary-color;
					border-bottom: 10px solid #d1d1d1;
				}
			}

			p {
				margin: 0 20px 20px 20px;
				padding: 55px 0 0 0;
				color: #fff;

				@include breakpoint(large) {
					margin: 20px 20px 20px 50px;
				}
			}

			.buttons-holder {

				@include breakpoint(large) {
					margin-left: 50px;
				}
			}

			.button {
				font-size: rem-calc(16);
				font-weight: 400;
				text-transform: uppercase;

				@include breakpoint(large) {
					margin-left: rem-calc(10);
				}
				@include breakpoint(xlarge) {
					margin-left: rem-calc(15);
				}

				&:first-child {
					margin-left: 0;
				}
			}
		}

		.fill-skew {
			display: none;
			float: right;
			margin-top: rem-calc(28);
			background: #fff;
			width: rem-calc(45);
			height: calc(100% - 10px);
			-ms-transform: skew(0, 50deg); /* IE 9 */
		    -webkit-transform: skew(0, 50deg); /* Safari */
		    transform: skew(0, 50deg); /* Standard syntax */

		    @include breakpoint(large) {
		    	display: block;
		    }
		}
	}