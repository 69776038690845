
	header.header {
		padding: rem-calc(15) 0 0 0;
		background: $primary-color;
		min-height: rem-calc(80);

		@include breakpoint(large) {
			min-height: auto;
		}

		.contact {
			margin-right: rem-calc(30);
			font-size: rem-calc(16);
			color: #fff;
			font-weight: bold;

			@include breakpoint(large) {
				font-size: rem-calc(20);
			}

			i {
				padding-right: rem-calc(5);
				vertical-align: middle;
				font-size: rem-calc(24);

				@include breakpoint(large) {
					font-size: rem-calc(32);
				}
			}
		}

		.button {
			margin: 0;
			background: #0d3243;
			font-size: rem-calc(14);
			font-weight: 400;

			@include breakpoint(medium) {
				margin-bottom: rem-calc(16);
				padding-left: rem-calc(60);
				padding-right: rem-calc(60);
			}

			i {
				padding-right: 3px;
				font-size: rem-calc(18);
			}

		}
	}