
	.section.contact {
		padding-bottom: rem-calc(70);

		h3 {
			font-weight: 600;
			margin-bottom: 30px;
		}
		p {
			margin: 0;
			padding: 0;
		}

		address {
			font-style: normal;
		}

		.widget {
			margin-bottom: 60px;
		}

		textarea {
			min-height: rem-calc(105);
		}
		.button {
			display: block;
			width: 100%;
		}
	}